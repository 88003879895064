.menu-link {
  @apply block py-2 pr-4 pl-3 text-gray-800 bg-blue-700 rounded md:bg-transparent md:p-0 dark:text-white;
}
.menu-link > .active {
  @apply text-primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  @apply font-medium;
}
.btn-primary {
  @apply py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-white focus:outline-none bg-primary rounded-full border focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed !important;
}
p {
  @apply mb-3 font-normal text-gray-500 dark:text-gray-400 !important;
}

.html-stack {
  @apply hover:text-white hover:bg-[#E96228];
}
.css-stack {
  @apply hover:text-white hover:bg-[#2662E9];
}
.bootstrap-stack {
  @apply hover:text-white hover:bg-[#8512F3];
}
.tailwind-stack {
  @apply hover:text-white hover:bg-[#37BCF8];
}
.javascript-stack {
  @apply hover:text-white hover:bg-[#EFD81D];
}
.typescript-stack {
  @apply hover:text-white hover:bg-[#0076C6];
}
.jquery-stack {
  @apply hover:text-white hover:bg-[#1265A9];
}
.react-stack {
  @apply hover:text-white hover:bg-[#5ED2F3];
}
.redux-stack {
  @apply hover:text-white hover:bg-[#7348B6];
}
.next-stack {
  @apply hover:text-white hover:bg-[#000000];
}
.nodejs-stack {
  @apply hover:text-white hover:bg-[#6FA461];
}
.mongodb-stack {
  @apply hover:text-white hover:bg-[#118E4C];
}
.php-stack {
  @apply hover:text-white hover:bg-[#7377AD];
}
.laravel-stack {
  @apply hover:text-white hover:bg-[#EB5548];
}
.mysql-stack {
  @apply hover:text-white hover:bg-[#035D85];
}
.express-stack {
  @apply hover:text-white hover:bg-[#474747];
}
.nestjs-stack {
  @apply hover:text-white hover:bg-[#E0234D];
}

.project-description {
  @apply my-6;
}
.project-description p {
  @apply text-gray-500 text-justify !important;
}
.project-description ol {
  @apply list-disc text-gray-500 mb-3 !important;
}

.form-group {
  @apply flex flex-col space-y-2 mb-2;
}

.form-group label {
  @apply text-gray-700 font-medium;
}

.form-control {
  @apply rounded-sm !important;
}

.skill-box {
  max-width: 132px !important;
  min-height: 164px !important;
  margin: auto;
  @apply bg-gray-100 text-gray-600 hover:shadow-md cursor-pointer rounded-md p-5;
}
